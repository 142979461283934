import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PriceCalculatorForm from './PriceCalculatorForm';
import ItemsTable from './ItemTable/ItemsTable';
import { ILineItems, IPaymentTerm, ITDSTax } from '../interface';
import { addDays, addMonths, endOfMonth, format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { gst_treatment } from './BiilsConfig';
import React from 'react';

interface IBillUploadFormProps {
  isSubmitting: boolean;
  bill: any;
  tds_taxes: ITDSTax[];
  poId?: string;
  onBillSubmit: (data: any) => void;
  payment_terms: IPaymentTerm[];
  formSubmitted: boolean;
}

interface IData {
  name: string;
  value: string | number;
}

const BillUploadForm = ({
  isSubmitting,
  bill,
  tds_taxes,
  onBillSubmit,
  payment_terms,
  formSubmitted
}: IBillUploadFormProps) => {
  const navigate = useNavigate();
  const { control, reset, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: { ...bill }
  });

  const { fields, remove } = useFieldArray({
    control,
    name: 'line_items'
  });

  const onSubmit = (data: any) => {
    let tempData = JSON.parse(JSON.stringify(data));
    const { custom_fields } = tempData;
    let filteredCF = custom_fields.map((cf: any) => {
      return { customfield_id: cf.customfield_id, value: cf.value };
    });
    tempData = { ...tempData, custom_fields: filteredCF };
    const {
      sub_total,
      tax_total,
      taxes,
      tds_amount,
      total,
      vendor_name,
      ...filteredData
    } = tempData;
    onBillSubmit(filteredData);
  };

  const onTDSChange = (tds_id: string) => {
    setValue('tds_tax_id', tds_id);
    if (tds_taxes) {
      let tds: ITDSTax | null =
        tds_taxes?.find((item: ITDSTax) => item.tax_id === tds_id) || null;
      if (tds) {
        setValue(
          'tds_amount',
          getValues('sub_total') * (tds?.tax_percentage / 100)
        );
        setValue('tds_percent', tds?.tax_percentage);
      }
    }
  };

  const data = [
    {
      name: 'Order Number',
      value: bill?.reference_number
    },
    {
      name: 'Vendor Name',
      value: bill?.vendor_name
    },
    {
      name: 'Source of Supply',
      value: bill?.source_of_supply
    },
    {
      name: 'Destination of Supply',
      value: bill?.destination_of_supply
    },
    {
      name: 'Branch Name',
      value: bill?.branch_name
    },
    {
      name: 'GST Treatment',
      value:
        gst_treatment?.find((item) => item.value === bill?.gst_treatment)
          ?.label || bill?.gst_treatment
    },
    {
      name: 'GSTIN',
      value: bill?.gst_no
    }
  ];

  const onChangePaymentTerms = (payment_term: IPaymentTerm) => {
    setValue('payment_terms', payment_term?.payment_terms);
    if (payment_term?.payment_terms_label === 'Due end of the month')
      setValue(
        'due_date',
        format(endOfMonth(new Date(bill?.date)), 'yyyy-LL-dd')
      );
    else if (payment_term?.payment_terms_label === 'Due end of next month')
      setValue(
        'due_date',
        format(endOfMonth(addMonths(new Date(bill?.date), 1)), 'yyyy-LL-dd')
      );
    else
      setValue(
        'due_date',
        format(
          addDays(new Date(bill?.date), payment_term?.payment_terms),
          'yyyy-LL-dd'
        )
      );
  };

  const onRowsChange = (lines: ILineItems[]) => {
    setValue('line_items', lines);
    let subTotal = lines?.reduce(
      (sum: number, item: ILineItems) => (sum += item?.item_total),
      0
    );
    setValue('sub_total', subTotal);
    let taxTotal = lines?.reduce(
      (sum: number, item: ILineItems) =>
        (sum += item?.item_total * (item?.tax_percentage / 100)),
      0
    );
    setValue('tax_total', taxTotal);
    let tdsTotal = getValues('tds_amount');
    setValue('total', subTotal + taxTotal + tdsTotal);
  };

  return (
    <>
      <Grid container spacing={4} p={4}>
        <Grid item xs={12}>
          <Typography variant="h2">Details</Typography>
        </Grid>
        {data.map((item: IData, index: number) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Typography>{item.name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{item.value}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Divider />
      <form onSubmit={(e) => handleSubmit(onSubmit)(e)}>
        <Grid container spacing={4} p={4}>
          <Grid item xs={2}>
            <Typography>Bill#*</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="bill_number"
              control={control}
              disabled={formSubmitted}
              render={({ field }) => <TextField size="small" {...field} />}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Vendor Invoice Number*</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000002180003'
              )}].value`}
              control={control}
              render={({ field }) => (
                <TextField
                  disabled={formSubmitted}
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    field.onChange((field.value = event.target.value))
                  }
                  value={field.value}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Bill Date</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={formSubmitted}
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Due Date</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="due_date"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={formSubmitted}
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Payment Schedule On</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000011852127'
              )}].value`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={formSubmitted}
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Actual Invoice Date</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={`custom_fields[${bill?.custom_fields?.findIndex(
                (item: any) => item?.customfield_id === '303149000029275915'
              )}].value`}
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disabled={formSubmitted}
                    value={field.value === '' ? null : new Date(field.value)}
                    onChange={(newValue: any) =>
                      field.onChange(
                        (field.value = format(new Date(newValue), 'yyyy-LL-dd'))
                      )
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography>Payment Terms</Typography>
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="payment_terms_label"
              control={control}
              render={({ field }) => (
                <Select
                  disabled={formSubmitted}
                  size="small"
                  value={
                    field.value === 'Due on Receipt'
                      ? payment_terms[0].payment_terms_label
                      : field.value
                  }
                  onChange={(event: SelectChangeEvent) => {
                    let term = payment_terms.find(
                      (item: IPaymentTerm) =>
                        item?.payment_terms_label === event.target.value
                    );
                    if (term) {
                      field.onChange((field.value = event.target.value));
                      onChangePaymentTerms(term);
                    }
                  }}
                >
                  {payment_terms.map((item: IPaymentTerm, index: number) => (
                    <MenuItem key={index} value={item?.payment_terms_label}>
                      {item?.payment_terms_label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={2}>
            <Typography>Notes</Typography>
          </Grid>
          <Grid item xs={10}>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  disabled={formSubmitted}
                  size="small"
                  {...field}
                  multiline
                  InputProps={{
                    style: {
                      height: '100px',
                      background: '#F7F8F9'
                    }
                  }}
                  placeholder="Please add notes"
                  rows={4}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <ItemsTable
              lineItems={fields}
              onDeleteRow={(index: number) => remove(index)}
              onRowsChange={onRowsChange}
              disabled={formSubmitted}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={5}>
            <PriceCalculatorForm
              subTotal={watch('sub_total')}
              discount={watch('discount')}
              taxTotal={watch('tax_total')}
              tds={watch('tds_amount')}
              total={watch('total')}
              taxes={tds_taxes}
              tdsTaxId={watch('tds_tax_id') || ''}
              onTDSChange={onTDSChange}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={4}
          p={8}
        >
          <Button
            variant="text"
            sx={{
              fontWeight: 500,
              padding: '6px 12px',
              fontSize: '14px',
              borderRadius: '4px'
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{
              fontWeight: 500,
              padding: '6px 12px',
              fontSize: '14px',
              borderRadius: '4px'
            }}
            type="submit"
            disabled={isSubmitting || formSubmitted}
            endIcon={isSubmitting && <CircularProgress size={18} />}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default BillUploadForm;
